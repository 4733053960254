import { css } from "@emotion/react";

import { colors, dimensions } from "@styles";

export const hero = css`
  margin-bottom: 2rem;

  @media (min-width: ${dimensions.fullHD}) {
    display: flex;
    gap: 2rem;
  }
`;

export const content = css`
  margin-top: 1.5rem;
`;

export const welcomeTextContainer = css`
  flex-basis: 0;
  flex-grow: 2;
`;

export const imageContainer = css`
  display: none;

  @media (min-width: ${dimensions.fullHD}) {
    display: flex;  
    justify-content: center;
    flex-basis: 0;
    flex-grow: 1;
  }
`;

export const svg = css`
  max-width: 20rem;
  height: 100%;
`;

export const link = css`
  color: ${colors.blue.darkFamily.light};
  text-decoration: underline;

  &:hover,
  &:active,
  &:focus {
    color: ${colors.blue.darkFamily.dark};
  }
`;
