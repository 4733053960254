import { FC } from "react";

import { Box, Typography } from "@aviary";

import * as styles from "./NewUpdateInfoBox.styles";

interface NewUpdateInfoBoxType {
  title: string;
  text: string;
  link?: string;
  linkText?: string;
}

interface Props {
  info: NewUpdateInfoBoxType;
}

const NewUpdateInfoBox: FC<Props> = ({ info }) => {
  const { title, text, link, linkText } = info;

  return (
    <Box css={styles.box}>
      <Typography type="h4" sizeOverride="h5">
        {title}
      </Typography>
      <Typography sizeOverride="footnote" css={styles.updateText}>
        {text}
      </Typography>
      <Typography type="footnote" css={styles.learnMore}>
        <a href={link}>{linkText}</a>
      </Typography>
    </Box>
  );
};

export { NewUpdateInfoBox };
export type { NewUpdateInfoBoxType };
