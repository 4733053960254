import { FC } from "react";

import { Typography } from "@aviary";

import * as styles from "./SupportAndStatus.styles";

const SupportAndStatus: FC = () => {
  return (
    <div css={styles.container}>
      <div css={styles.box}>
        <Typography type="h2">Need help?</Typography>
        <Typography>
          Our amazing support team is here to aid you with technical questions that come up as you build.
        </Typography>
        <Typography>
          <b>Contact:</b>{" "}
          <a href="mailto:api-support@fullscript.com" css={styles.link}>
            api-support@fullscript.com
          </a>
        </Typography>
        <a
          href="https://docs.google.com/forms/d/e/1FAIpQLSdReDkGQQQ35HmYU_67XTlPGn1sqpF2w60uiJMnfNB_83Bkjg/viewform"
          target="_blank"
          rel="noopener noreferrer"
          css={[styles.link, styles.ctaLink]}
        >
          View support doc
        </a>
      </div>
      <div css={styles.box}>
        <Typography type="h2">Service Status</Typography>
        <Typography>
          To view any status changes to the Fullscript platform, head to our service status page.
        </Typography>
        <a href="https://status.fullscript.io/" css={[styles.link, styles.ctaLink]}>
          Check it out
        </a>
      </div>
    </div>
  );
};

export { SupportAndStatus };
