import { FC } from "react";

import { Typography, Button, Box } from "@aviary";

import * as styles from "./HomePageInfoBox.styles";

interface HomePageInfoBoxType {
  title: string;
  text: string;
  buttonLink: string;
  buttonText: string;
  target?: string;
  isPrimary?: boolean;
}

interface Props {
  info: HomePageInfoBoxType;
}

const HomePageInfoBox: FC<Props> = ({ info }) => {
  const { title, text, buttonLink, buttonText, target, isPrimary } = info;

  return (
    <Box css={styles.container}>
      <div css={styles.content}>
        <Typography type="h4" css={isPrimary ? styles.primaryTitle : styles.darkTitle}>
          {title}
        </Typography>
        <Typography>{text}</Typography>
      </div>

      <Button isSize="small" isColor={isPrimary ? "primary" : "dark"} href={buttonLink} target={target} >
        {buttonText}
      </Button>
    </Box>
  );
};

export { HomePageInfoBox };
export type { HomePageInfoBoxType };
