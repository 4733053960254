import { FC } from "react";

import { Typography } from "@aviary";

import HouseSVG from "../../assets/svgs/green-house.svg";

import * as styles from "./UnauthHero.styles";

const UnauthHero: FC = () => {
  const renderRegularSignInLink = () => (
    <a href="https://us.fullscript.com" target="_blank" css={styles.link}>
      sign-in
    </a>
  );

  return (
    <div css={styles.hero}>
      <div css={styles.welcomeTextContainer}>
        <Typography type="h1">Welcome to the Fullscript API</Typography>
        <Typography css={styles.content}>
          Fullscript’s API bridges the gap between practitioners and integrative healthcare. We’re
          all about simplifying workflows. Our API is centered around our treatment plan tool and
          allows your practitioners to simplify the path to lifelong wellness by granting access to
          personalized treatment planning, active wellness support, and healthcare’s best
          supplements and wellness products. With a focus on developer experience, we hope that your
          journey integrating Fullscript inside your EHR (or other health solution software) is fast
          and effective.
        </Typography>

        <Typography>
          When you integrate with the Fullscript API, you’re helping your practitioners expand their
          solution offering by enabling them to incorporate wellness and personalized care into
          their toolkit at scale. With our intelligent search function, 40,000+ products from more
          than 450 brands, and helpful adherence tracking, practitioners will be able to provide
          patients with the care they need.
        </Typography>

        <Typography>
          Since Fullscript owns both the prescribing and distribution of all products in our
          catalog, we take care of the picking, packing, and shipping of products through
          contactless delivery. This way, practitioners can focus on what really matters — helping
          their patients with their wellness journey.
        </Typography>

        <Typography>
          If you are a little confused you may be in the wrong spot. Go back to <b>Patient</b> or{" "}
          <b>Practitioner</b> {renderRegularSignInLink()} if you’re feeling lost!
        </Typography>
      </div>
      <div css={styles.imageContainer}>
        <HouseSVG css={styles.svg} />
      </div>
    </div>
  );
};

export { UnauthHero };
