import { Global } from "@emotion/react";
import { FC } from "react";

import { PageHeader } from "@components";

import * as styles from "./NoAuthPageWrapper.styles";

const NoAuthPageWrapper: FC = ({ children }) => {
  return (
    <>
      <Global styles={styles.whiteBackground} />

      <PageHeader />
      {children}
    </>
  );
};

export { NoAuthPageWrapper };
