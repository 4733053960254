import { css } from "@emotion/react";

import { colors } from "@styles";

export const whiteBackground = css`
  html {
    background-color: ${colors.white};
  }

  body {
    background-color: ${colors.white};
  }

  * {
    scroll-margin-top: 8rem;
  }
`;
