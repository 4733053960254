import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Link from "next/link";
import { FC } from "react";

import { Typography } from "@aviary";

import { docsQuickLinks } from "./docsQuickLinksData";
import type { QuickLinkType } from "./docsQuickLinksData";

import * as styles from "./DocsQuickLinks.styles";

const DocsQuickLinks: FC = () => {
  const renderQuickLink = (quickLink: QuickLinkType) => {
    const { title, text, buttonText, buttonLink, icon } = quickLink;

    return (
      <article key={title} css={styles.container}>
        <div css={styles.circle}>
          <FontAwesomeIcon icon={icon} css={styles.icon} />
        </div>
        <div css={styles.content}>
          <Typography type="h3">{title}</Typography>
          <Typography>{text}</Typography>
          <Link href={buttonLink} passHref>
            <a css={styles.link}>{buttonText}</a>
          </Link>
        </div>
      </article>
    );
  };

  return (
    <section css={styles.list}>
      {docsQuickLinks.map(quickLink => renderQuickLink(quickLink))}
    </section>
  );
};

export { DocsQuickLinks };
