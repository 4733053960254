import { css } from "@emotion/react";

import { colors, dimensions } from "@styles";

export const box = css`
  padding: 1rem;
  border-radius: ${dimensions.semiRoundedCardRadius};

  * {
    color: ${colors.grey.darkFamily.normal};
  }
`;

export const updateText = css`
  line-height: 1.25rem;
`;

export const learnMore = css`
  margin-top: 0.5rem;
  align-self: flex-end;
  text-decoration: underline;
`;
