export const createAccountCTA = {
  title: "New to Fullscript Integrate?",
  text: "Give the integration a try by signing up and setting up a sandbox environment",
  buttonLink: "/registration",
  buttonText: "Create API Account",
};

export const signInCTA = {
  title: "Already a member of Fullscript integrate?",
  text: "Go ahead and sign in!",
  buttonLink: "/signin",
  buttonText: "Sign in",
  isPrimary: true,
};

export const viewDiagramCTA = {
  title: "Get to know the process",
  text: "Click here to see a depiction of the process you will ge though as you set up and deploy ",
  buttonLink: "/static/pdfs/ApiDevelopmentProcess.pdf",
  buttonText: "View Diagram",
  target: "_blank",
};
