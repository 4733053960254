import { css } from "@emotion/react";

import { colors, dimensions } from "@styles";

export const container = css`
  display: grid;
  gap: 1.5rem;

  @media screen and (min-width: ${dimensions.desktop}) {
    grid-template-columns: 1fr 1fr;
  }
`;

export const box = css`
  background-color: ${colors.grey.lightFamily.normal};
  border-radius: ${dimensions.semiRoundedCardRadius};
  padding: 2rem;
`;

export const link = css`
  color: ${colors.blue.darkFamily.light};

  &:hover,
  &:active,
  &:focus {
    color: ${colors.blue.darkFamily.dark};
  }
`;

export const ctaLink = css`
  text-decoration: underline;
`;
