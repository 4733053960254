import { FC } from "react";

import { Box, Typography } from "@aviary";

import { partnerLogos } from "./partnerLogosData";

import * as styles from "./JoinTheFamily.styles";

const JoinTheFamily: FC = () => {
  return (
    <Box css={styles.box}>
      <Typography type="h2" css={styles.title}>
        Join the Family
      </Typography>
      <div css={styles.logosContainer}>
        {partnerLogos.map(info => (
          <div css={styles.circle} key={info.title}>
            {/* eslint-disable-next-line @next/next/no-img-element */}
            <img src={info.imageLink} />
          </div>
        ))}
      </div>
    </Box>
  );
};

export { JoinTheFamily };
