import { FC } from "react";

import { Typography } from "@aviary";
import { NewUpdateInfoBox, NewUpdateInfoBoxType } from "@components";
import { NoAuthPageWrapper } from "@components/NoAuthPageWrapper";
import { UserType } from "@types";

import { AuthHomePage } from "./AuthHomePage";
import { UnauthHomePage } from "./UnauthHomePage";
import { newUpdates } from "./newUpdatesData";

import * as styles from "./HomePage.styles";

interface Props {
  user: UserType;
}

const HomePage: FC<Props> = ({ user }) => {
  const renderContent = () => (user ? <AuthHomePage user={user} /> : <UnauthHomePage />);

  const renderAside = () => {
    return (
      <div css={styles.asideWrapper}>
        <Typography css={styles.asideText} type="h3">
          What's new?
        </Typography>

        <div css={styles.updates}>
          {newUpdates.map((info: NewUpdateInfoBoxType) => (
            <NewUpdateInfoBox key={info.title} info={info} />
          ))}
        </div>
      </div>
    );
  };

  return (
    <NoAuthPageWrapper>
      <div css={styles.wrapper}>
        <aside css={styles.aside}>{renderAside()}</aside>

        <div css={styles.content}>{renderContent()}</div>
      </div>
    </NoAuthPageWrapper>
  );
};

export { HomePage };
