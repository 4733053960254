import type { FC, HTMLProps } from "react";

import * as styles from "./Box.styles";

interface BoxProps extends HTMLProps<HTMLDivElement> {
  /**
   * Removes gutters made by padding from the Container component. Uses negative margin.
   *
   * @default false
   */
  isGutterless?: boolean;
}

const Box: FC<BoxProps> = ({ isGutterless, children, ...rest }: BoxProps) => {
  const css = [styles.box, isGutterless && styles.gutterless];
  return (
    <section css={css} {...rest}>
      {children}
    </section>
  );
};

export type { BoxProps };
export { Box };
