export const caseStudies = [
  {
    title: "Get to know the process",
    text: "Click here to see an infographic of the process you will go though as you set up and deploy",
    buttonLink: "/static/pdfs/ApiDevelopmentProcess.pdf",
    buttonText: "View Diagram",
    target: "_blank",
  },
  {
    title: "Dr. Sage Wheeler case study",
    text: "When the owner and director of SageMED, Dr. Sage Wheeler, ND, found his practice rapidly growing and the number of providers increasing, he realized that they were quickly outgrowing their inventory and workflow requirements…",
    buttonLink: "/static/pdfs/DrWheelerCaseStudy.pdf",
    buttonText: "Take a look",
    target: "_blank",
  },
];
