import { FC } from "react";

import { DocsQuickLinks, JoinTheFamily, HomePageInfoBox, UnauthHero } from "@components";

import { createAccountCTA, viewDiagramCTA } from "./homePageCTAData";

import * as styles from "./UnauthHomePage.styles";

const UnauthHomePage: FC = () => {
  return (
    <>
      <UnauthHero />
      <div css={styles.wrapper}>
        <div>
          <HomePageInfoBox info={createAccountCTA} />
          <HomePageInfoBox info={viewDiagramCTA} />
        </div>
        <DocsQuickLinks />
        <JoinTheFamily />
      </div>
    </>
  );
};

export { UnauthHomePage };
