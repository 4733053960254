import { css } from "@emotion/react";

import { colors, dimensions } from "@styles";

export const wrapper = css`
  position: relative;
`;

export const aside = css`
  display: none;

  @media (min-width: ${dimensions.desktop}) {
    display: block;
    position: fixed;
    top: 0;
    background: ${colors.grey.lightFamily.normal};
    height: 100vh;
    width: clamp(11rem, 30%, 20rem);
    overflow-y: auto;
    padding: 4.5rem 1rem 2rem;
  }
`;

export const content = css`
  display: flex;
  flex-direction: column;
  padding: 4rem 1rem;

  @media (min-width: ${dimensions.desktop}) {
    margin-left: clamp(11rem, 30%, 20rem);
    padding: 4rem 3rem;
  }
`;

export const asideWrapper = css`
  display: flex;
  flex-direction: column;
  max-width: 16rem;
  margin: auto;

  @media (min-width: ${dimensions.desktop}) {
    padding-top: 2.5rem;
  }
`;

export const asideText = css`
  color: ${colors.blue.darkFamily.light};
  font-size: 1.25rem;
`;

export const updates = css`
  margin-top: 0.5rem;
`;
