import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faFlag, faSearch, faCode } from "@fortawesome/pro-regular-svg-icons";

export interface QuickLinkType {
  title: string;
  text: string;
  buttonText: string;
  buttonLink: string;
  icon: IconProp;
}

export const docsQuickLinks: QuickLinkType[] = [
  {
    title: "Getting started",
    text: "Everything you'll need to know when integrating with Fullscript. Through our docs, you'll gain an understanding of the Fullscript platform and discover the best integration options for your ecosystem.",
    buttonText: "Start with us",
    buttonLink: "/docs/getting-started",
    icon: faFlag,
  },
  {
    title: "How-to guides",
    text: "Practical step-by-step guides to help you achieve a specific goal. Most useful when you're trying to get something done.",
    buttonText: "Go to guides",
    buttonLink: "/docs/how-to-guides",
    icon: faSearch,
  },
  {
    title: "Technical reference",
    text: "A detailed reference for each of our REST API calls and a summary of our event system.",
    buttonText: "Explore technical reference",
    buttonLink: "/docs/technical-reference",
    icon: faCode,
  },
];
