import { css } from "@emotion/react";

import { colors, dimensions } from "@styles";
import { hexToRgba } from "@styles/helpers";

export const container = css`
  display: flex;
  flex-wrap: wrap;
  padding: 1rem 2rem;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 1.5rem;
  box-shadow: 0 1px 8px 0 ${hexToRgba(colors.grey.base, 0.25)};
`;

export const content = css`
  @media (min-width: ${dimensions.desktop}) {
    width: 60%;
  }
`;

export const darkTitle = css`
  color: ${colors.blue.darkFamily.dark};
`;

export const primaryTitle = css`
  color: ${colors.green.darkFamily.dark};
`;
