export const newUpdates = [
  {
    title: "Integrate quickly with Fullscript.js",
    text: "Fullscript.js is a library that uses Fullscript’s powerful API so you can easily embed the treatment plan tool directly into your application.",
    link: "/docs/how-to-guides/fullscript-js/what-is-fullscript-js",
    linkText: "Learn more here",
  },
  {
    title: "Fullscript-curated protocols now available",
    text: "Evidence-informed protocols offer a foundation that can be customized to fit a patient's treatment and adjusted to meet their needs.",
    link: "/docs/technical-reference#list-all-fullscript-protocols",
    linkText: "See endpoint here",
  },
  {
    title: "Invite your team members",
    text: "You can now manage your team within the API dashboard and invite new team members to join. Go to 'My Team' to see and manage all users within your API account.",
  },
  {
    title: "Role-based access control",
    text: "The Fullscript API is now powered by role-based access control. If you are an existing customer this change is now available for you to upgrade to inside your API dashboard. See the guide available in your dashboard also for technical details.",
  },
];
