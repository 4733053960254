import { css } from "@emotion/react";

import { colors, dimensions } from "@styles";

export const container = css`
  background-color: ${colors.grey.lightFamily.normal};
  padding: 1.5rem;
  border-radius: ${dimensions.borderRadius};
`;

export const heading = css`
  color: ${colors.blue.darkFamily.light};
`;

export const linksGroup = css`
  display: flex;
  flex-wrap: wrap;
  column-gap: 5.625rem;
  row-gap: 0.5rem;
`;

export const link = css`
  color: ${colors.blue.darkFamily.light};

  &:hover,
  &:active,
  &:focus {
    color: ${colors.blue.darkFamily.dark};
  }
`;
