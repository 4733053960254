import { css } from "@emotion/react";

import { colors, dimensions } from "@styles";

export const box = css`
  background: ${colors.grey.lightFamily.normal};
  padding: 2rem 2.5rem;
`;

export const title = css`
  color: ${colors.blue.darkFamily.light};
  padding-bottom: 1.5rem;
`;

export const circle = css`
  height: 100px;
  width: 100%;
  background-color: ${colors.white};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
  padding: 1rem;

  @media (min-width: ${dimensions.desktop}) {
    height: 150px;
  }
`;

export const logosContainer = css`
  display: grid;
  grid-template-columns: repeat(auto-fill, 100px);
  justify-content: space-between;
  grid-gap: 2.5rem;

  @media (min-width: ${dimensions.desktop}) {
    grid-template-columns: repeat(auto-fill, 150px);
  }
`;
