export interface RecommendedReadType {
  title: string;
  link: string;
}

export const recommendedReads: RecommendedReadType[] = [
  {
    title: "Fullscript.js guide",
    link: "/docs/how-to-guides/fullscript-js/what-is-fullscript-js",
  },
  {
    title: "Intro to Fullscript",
    link: "/docs/getting-started/introduction/what-is-fullscript",
  },
  {
    title: "OAuth Guide",
    link: "/docs/how-to-guides/oauth/oauth-overview",
  },
  {
    title: "Webhooks Guide",
    link: "/docs/how-to-guides/webhooks/what-are-webhooks",
  },
];
