import { css } from "@emotion/react";

import { colors, dimensions } from "@styles";
import { cardShadow } from "@styles/emotion-styles/baseStyles";

export const box = css`
  display: flex;
  flex-direction: column;
  background-color: ${colors.backgroundWhite};
  border-radius: ${dimensions.borderRadius};
  ${cardShadow};

  color: ${colors.grey.base};

  &:not(:last-child) {
    margin-bottom: ${dimensions.containerMarginMobile};
  }
`;

export const gutterless = css`
  @media screen and (max-width: ${dimensions.tabletMax}) {
    margin-left: -${dimensions.containerMarginMobile};
    margin-right: -${dimensions.containerMarginMobile};
  }
`;
