import Link from "next/link";
import { FC } from "react";

import { Typography } from "@aviary";

import { recommendedReads } from "./recommendedReadsData";
import type { RecommendedReadType } from "./recommendedReadsData";

import * as styles from "./RecommendedReads.styles";

const RecommendedReads: FC = () => {
  const renderLinks = () =>
    recommendedReads.map(({ title, link }: RecommendedReadType) => (
      <Link href={link} key={title} passHref>
        <a css={styles.link}>{title}</a>
      </Link>
    ));

  return (
    <section css={styles.container}>
      <Typography type="h4" css={styles.heading}>
        Recommended Reads
      </Typography>

      <div css={styles.linksGroup}>{renderLinks()}</div>
    </section>
  );
};

export { RecommendedReads };
