export const partnerLogos = [
  {
    title: "3x4Genetics",
    imageLink: "https://assets.fullscript.io/integration-partner-logos/3x4genetics.png",
    height: 90,
    width: 130,
  },
  {
    title: "Acusimple",
    imageLink: "https://assets.fullscript.io/integration-partner-logos/acusimple.png",
    height: 80,
    width: 130,
  },
  {
    title: "Biocanic",
    imageLink: "https://assets.fullscript.io/integration-partner-logos/biocanic.png",
    height: 90,
    width: 150,
  },
  {
    title: "Bodysite",
    imageLink: "https://assets.fullscript.io/integration-partner-logos/bodysite.png",
    height: 30,
    width: 120,
  },
  {
    title: "Cerbo",
    imageLink: "https://assets.fullscript.io/integration-partner-logos/cerbo.png",
    height: 45,
    width: 120,
  },
  {
    title: "Charm",
    imageLink: "https://assets.fullscript.io/integration-partner-logos/charm.png",
    height: 60,
    width: 130,
  },
  {
    title: "DrChrono",
    imageLink: "https://assets.fullscript.io/integration-partner-logos/drchrono.png",
    height: 40,
    width: 130,
  },
  {
    title: "Healthie",
    imageLink: "https://assets.fullscript.io/integration-partner-logos/healthie.png",
    height: 60,
    width: 120,
  },
  {
    title: "Jane",
    imageLink: "https://assets.fullscript.io/integration-partner-logos/jane.png",
    height: 65,
    width: 110,
  },
  {
    title: "Kalix",
    imageLink: "https://assets.fullscript.io/integration-partner-logos/kalix.png",
    height: 50,
    width: 120,
  },
  {
    title: "LivingPlate",
    imageLink: "https://assets.fullscript.io/integration-partner-logos/livingplate.png",
    height: 60,
    width: 130,
  },
  {
    title: "NaturaeSoft",
    imageLink: "https://assets.fullscript.io/integration-partner-logos/naturaesoft.png",
    height: 80,
    width: 100,
  },
  {
    title: "NutriQ",
    imageLink: "https://assets.fullscript.io/integration-partner-logos/nutriq.png",
    height: 80,
    width: 80,
  },
  {
    title: "ODX",
    imageLink: "https://assets.fullscript.io/integration-partner-logos/odx.png",
    height: 30,
    width: 120,
  },
  {
    title: "Optimantra",
    imageLink: "https://assets.fullscript.io/integration-partner-logos/optimantra.png",
    height: 70,
    width: 140,
  },
  {
    title: "Oxygen",
    imageLink: "https://assets.fullscript.io/integration-partner-logos/oxygen.png",
    height: 100,
    width: 100,
  },
  {
    title: "PatientNow",
    imageLink: "https://assets.fullscript.io/integration-partner-logos/patientnow.png",
    height: 30,
    width: 130,
  },
  {
    title: "Power2Practice",
    imageLink: "https://assets.fullscript.io/integration-partner-logos/power2practice.png",
    height: 60,
    width: 120,
  },
  {
    title: "PracticeBetter",
    imageLink: "https://assets.fullscript.io/integration-partner-logos/practicebetter.png",
    height: 70,
    width: 140,
  },
  {
    title: "UnifiedPractice",
    imageLink: "https://assets.fullscript.io/integration-partner-logos/unifiedpractice.png",
    height: 80,
    width: 100,
  },
];
