import { FC } from "react";

import { Typography, Button } from "@aviary";
import { HomePageInfoBox, DocsQuickLinks, RecommendedReads, SupportAndStatus } from "@components";
import type { HomePageInfoBoxType } from "@components";
import { UserType } from "@types";

import { caseStudies } from "./caseStudiesData";

import * as styles from "./AuthHomePage.styles";

interface Props {
  user: UserType;
}

const AuthHomePage: FC<Props> = ({ user: { firstName, lastName } }) => {
  const fullName = `${firstName} ${lastName}`;

  const renderCaseStudies = () => (
    <div>
      <Typography>Learn a little more about...</Typography>
      {caseStudies.map((info: HomePageInfoBoxType) => (
        <HomePageInfoBox key={info.title} info={info} />
      ))}
    </div>
  );

  return (
    <>
      <section css={styles.hero}>
        <Typography type="h1">Welcome back, {fullName}</Typography>
        <Button href="/applications">View dashboard</Button>
      </section>
      <div css={styles.wrapper}>
        {renderCaseStudies()}
        <SupportAndStatus />
        <DocsQuickLinks />
        <RecommendedReads />
      </div>
    </>
  );
};

export { AuthHomePage };
