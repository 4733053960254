import { css } from "@emotion/react";

import { colors, dimensions } from "@styles";

export const list = css`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

export const container = css`
  display: flex;
  max-width: 45rem;
  gap: 1.5rem;
`;

export const circle = css`
  width: 100%;
  max-width: 3.125rem;
  height: 3.125rem;
  border-radius: ${dimensions.circleRadius};
  background-color: ${colors.grey.lightFamily.normal};
  margin-top: 0.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
`;

export const icon = css`
  color: ${colors.blue.darkFamily.dark};
  font-size: 1.2rem;
`;

export const content = css`
  display: flex;
  flex-direction: column;
`;

export const link = css`
  align-self: flex-end;
  color: ${colors.grey.darkFamily.darker};
  text-decoration: underline;
`;
