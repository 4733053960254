import { GetServerSideProps, NextPage } from "next";

import { Head } from "@components";
import { HomePage } from "@scenes";
import { UserType } from "@types";
import { handleFetch } from "@utils/server";

interface Props {
  user: UserType;
}

const Index: NextPage<Props> = ({ user }) => {
  return (
    <>
      <Head title="Welcome to Fullscript Integrate" />

      <HomePage user={user} />
    </>
  );
};

export const getServerSideProps: GetServerSideProps = async ({ req }) => {
  const { data: account, response } = await handleFetch(req, "/account");

  if (!response.ok || account.error) {
    return {
      props: { user: null },
    };
  }

  const user: UserType = {
    ...(await handleFetch(req, "/users/current")).data,
  };

  return {
    props: { user },
  };
};

export default Index;
